// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contato-js": () => import("./../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-educacao-cursos-comissario-de-voo-js": () => import("./../src/pages/educacao/cursos/comissario-de-voo.js" /* webpackChunkName: "component---src-pages-educacao-cursos-comissario-de-voo-js" */),
  "component---src-pages-educacao-cursos-mecanica-de-aviao-js": () => import("./../src/pages/educacao/cursos/mecanica-de-aviao.js" /* webpackChunkName: "component---src-pages-educacao-cursos-mecanica-de-aviao-js" */),
  "component---src-pages-educacao-cursos-piloto-privado-js": () => import("./../src/pages/educacao/cursos/piloto-privado.js" /* webpackChunkName: "component---src-pages-educacao-cursos-piloto-privado-js" */),
  "component---src-pages-esportes-js": () => import("./../src/pages/esportes.js" /* webpackChunkName: "component---src-pages-esportes-js" */),
  "component---src-pages-governanca-js": () => import("./../src/pages/governanca.js" /* webpackChunkName: "component---src-pages-governanca-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sobre-nos-js": () => import("./../src/pages/sobre-nos.js" /* webpackChunkName: "component---src-pages-sobre-nos-js" */),
  "component---src-pages-transparencia-js": () => import("./../src/pages/transparencia.js" /* webpackChunkName: "component---src-pages-transparencia-js" */)
}

